<template>
  <div>
    <NModal
      preset="dialog"
      :title="`${teacherAccount} IP登录记录`"
      style="width: 850px;"
      v-model:show="modalShow"
      :show-icon="false"
      positive-text="确定"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
      :mask-closable="false"
    >
      <div style="padding: 20px 0;">
        <div style="margin-bottom: 10px; text-align: right;">
          <span style="line-height: 28px; margin-right: 10px;">已选：{{ selectIDArr.length }}</span>
          <NButton
            v-if="ownUrlPermission('school/monitor-log-delete')"
            type="error"
            size="small"
            :disabled="selectIDArr.length === 0"
            @click="handleClickDel"
          >删除所选记录</NButton>
        </div>
        <NDataTable
          size="small"
          :single-line="false"
          :row-key="({ id }) => id"
          :columns="columns"
          remote
          :data="loginRecordArr"
          :pagination="pagination"
          v-model:checked-row-keys="selectIDArr"
        />
      </div>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h, nextTick } from 'vue';
  import { NModal, NDataTable, NTime, NButton, useMessage, useDialog } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import ownUrlPermission from '@/utils/own-url-permission.js';
  import { resStatusEnum } from '@/enumerators/http.js';

  import { loginLogs, loginLogsDel } from '@/api/school-teacher.js';
  import { studentIPLoginRecords, loginLogsDel as studentLoginLogsDel } from '@/api/school-student';

  const emit = defineEmits(['list-update']);
  const props = defineProps({
    listType: { // teacher student
      type: String,
      required: true
    }
  });

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const dialog = useDialog();
  const loading = ref(false);

  const modalShow = ref(false);
  const teacherAccount = ref('');
  const teacherID = ref('');
  const selectIDArr = ref([]);

  const columns = [
    { type: 'selection' },
    // { title: '账号', key: 'admin_username' },
    {
      title: 'IP地址',
      render: ({ network_type, ip }) => h('span', null, [network_type, ip].filter(item => Boolean(ip)).join(' '))
    },
    {
      title: '归属地',
      render: ({ country, province, city }) => h('span', null, [country, province, city].filter(item => Boolean(item)).join(' '))
    },
    {
      title: '登录时间',
      render: ({ create_time }) => h(NTime, { time: create_time * 1000 })
    }
  ];
  const loginRecordArr = ref([]);
  const pagination = reactive({
    itemCount: 0,
    page: 1,
    pageSize: 10,
    showSizePicker: true,
    pageSizes: [10, 20, 30],
    onChange: page => {
      pagination.page = page;
      reqLoginRecordsFn();
    },
    onUpdatePageSize: pageSize => {
      pagination.pageSize = pageSize;
      pagination.onChange(1);
    }
  });

  function handleSubmit() {
    closeModal();
    return false;
  }
  function closeModal() {
    modalShow.value = false;
  }
  function reqLoginRecordsFn() {
    let reqFn;
    const reqData = {
      page: pagination.page,
      page_size: pagination.pageSize
    };
    switch (props.listType) {
      case 'teacher':
        reqFn = loginLogs;
        reqData['Teacher[id]'] = teacherID.value;
        break;
      case 'student':
        reqFn = studentIPLoginRecords;
        reqData['Student[id]'] = teacherID.value;
        break;
    }
    if (!reqFn) {
      return;
    }
    loading.value = true;
    reqFn(reqData).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { total, list } = data;
        if (Array.isArray(list)) {
          loginRecordArr.value = list;
          nextTick(() => {
            pagination.itemCount = Number(total) || 0;
          });
        }
        modalShow.value = true;
      }
    }).finally(() => {
      loading.value = false;
    });
  }
  function handleClickDel() {
    const num = selectIDArr.value.length;
    if (num > 0) {
      dialog.warning({
        title: '删除确认',
        content: `确定删除 ${num} 条记录？`,
        positiveText: '确定',
        negativeText: '取消',
        onPositiveClick: () => {
          let reqFn;
          const reqData = {};
          switch (props.listType) {
            case 'teacher':
              reqFn = loginLogsDel;
              reqData['School[log_ids]'] = selectIDArr.value;
              break;
            case 'student':
              reqFn = studentLoginLogsDel;
              reqData['log_ids'] = selectIDArr.value;
              break;
          }
          if (!reqFn) {
            return;
          }
          loading.value = true;
          reqFn(reqData).then(({ code }) => {
            if (code === SUCCESS) {
              message.success('删除成功');
              selectIDArr.value.splice(0);
              emit('list-update');
              reqLoginRecordsFn();
            }
          }).finally(() => {
            loading.value = false;
          });
        },
        onNegativeClick: () => {}
      });
    }
  }

  defineExpose({
    openModal({ username, student_num, id }) {
      switch (props.listType) {
        case 'teacher':
          teacherAccount.value = username;
          teacherID.value = id;
          break;
        case 'student':
          teacherAccount.value = student_num;
          teacherID.value = id;
          break;
      }
      selectIDArr.value.splice(0);
      pagination.onChange(1);
    }
  });
</script>