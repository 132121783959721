import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function teacherList(data) {
  return request({
    url: '/backend/web/teacher/index',
    method: GET,
    params: data
  });
}

export function teacherAccountStatus(data) {
  return request({
    url: '/backend/web/teacher/state',
    method: POST,
    data
  });
}

export function passwordReset(data) {
  return request({
    url: '/backend/web/teacher/reset',
    method: POST,
    data
  });
}

export function getResetedPwd(params) {
  return request({
    url: '/backend/web/teacher/reset',
    method: GET,
    params
  });
}

export function operationLogs(data) {
  return request({
    url: '/backend/web/teacher/operation-log',
    method: GET,
    params: data
  });
}

export function ipMonitorSetting(data) {
  return request({
    url: '/backend/web/teacher/teacher-ip',
    method: POST,
    data
  });
}

export function loginLogs(data) {
  return request({
    url: '/backend/web/teacher/login-log',
    method: GET,
    params: data
  });
}

export function loginLogsDel(data) {
  return request({
    url: '/backend/web/school/monitor-log-delete',
    method: POST,
    data
  });
}

export function loginLogsReset(data) {
  return request({
    url: '/backend/web/teacher/monitor-log-reset',
    method: POST,
    data
  });
}

/*
interface TeacherSpeakStatusData {
  'teacher_ids': string[];
  type: 1 | 2; // 1-公告表；2-互动问答表；
  status: 0 | 1; // 0-恢复；1-禁言
}
*/
export function teacherSpeakStatus(data) {
  return request({
    url: '/backend/web/course/article-taboo',
    method: POST,
    data
  });
}

/*
{
  'Teacher[id]': string;
  'Teacher[is_default]': number; // 0-否；1-是；
}
*/
export function teacherDemonstrateDataStatus(data) {
  return request({
    url: '/backend/web/teacher/operation-log',
    method: POST,
    data
  });
}